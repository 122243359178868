<!--
 * @Author: yangdongxu
 * @Date: 2023-06-25 08:49:29
 * @LastEditors: yangdongxu
 * @LastEditTime: 2023-06-25 19:44:57
 * @FilePath: \gtm-guarantee-web\src\views\login\userAgreement\Agreement.vue
-->
<!-- 用户协议 -->
<template>
  <div class="agreementClass">
    <div class="uc-top-header">
      <div class="top-logo-bg"></div>
    </div>
    <div class="about-body">
      <div class="content width-980">
        <h2 class="main-title">
          广联达 • 用户中心 用户协议
          <p class="pull-right ver-date">本版更新时间：2020年10月10日</p>
        </h2>
        <hr class="text-split-line"/>
        <div class="main-content mod-agreement">
          <div class="section section-content">
            <ul class="section-content-ul">
              <li>
                <p>
                  广联达科技股份有限公司及其子公司与分支机构（以下统称“广联达”或“我们”），我们一向倡导“以客户为中心”的文化。我们很荣幸向用户（包括企业用户及个人用户，以下统称“您”）提供广联达账户与网站、软件、应用、内容、产品与服务（以下统称“广联达服务”）。当您选择广联达服务，您的使用行为将视为对本协议的接受，并同意接受本协议各项条款的约束。因此，请您务必仔细阅读以下广联达《用户协议》（以下称“协议”）。
                </p>
                <p>
                  同时，请您务必仔细阅读
                  <a
                    target="_blank" @click="handleOpenArgument('/platform/privacy')" style="cursor: pointer"
                  >《隐私政策》</a
                  >，
                  <a
                    target="_blank" @click="handleOpenArgument('/platform/privacy')" style="cursor: pointer"
                  >《隐私政策》</a
                  >
                  将帮助您了解：我们如何收集、使用、共享以及储存您的个人信息。
                </p>
                <p>
                  广联达有权对本协议包含的条款随时更新并发出相应的通知，更新后的协议条款一旦公布即代替原来的协议条款。您可在本网站查阅最新版协议条款，如果涉及重要条款更新，我们将会再次征询您的同意。<b
                >在广联达修改本协议相关条款之后，若您不接受修改后的条款，请勿点击“立即注册”，同时请停止使用广联达提供的产品和服务。否则，您继续使用广联达提供的产品和服务将被视为您已接受了修改后的协议。</b
                >
                </p>
              </li>
              <li>
                <p class="title-1">1．注册与账号</p>
                <p>
                  1.1 部分广联达服务将要求您创建账号。您完成注册手续后，获得广联达账号的使用权。<b
                >您保证，您注册时所需提供的公司信息/个人信息在各方面均真实、准确、最新、完整，并不断更新注册资料，以符合及时、详尽准确的要求。您不得冒充任何其他人或实体。您同意：企业账户的操作行为将直接被视为企业的行为，同时，企业应当对其雇员做出的企业账户管理行为负责。</b
                >
                </p>
                <p>
                  1.2
                  <b
                  >您须对注册信息的真实性、合法性、有效性承担全部责任；不得冒充他人，不得利用他人的名义发布任何信息；不得创建虚假身份误导他人否则广联达有权立即停止提供服务，由此而产生的一切法律责任由您独自承担。</b
                  >
                </p>
                <p>
                  1.3
                  <b
                  >您使用广联达产品、服务的行为必须合法并符合本协议，您必须为自己注册账号下的一切行为负责，包括您所发表的任何内容以及由此产生的任何后果。您应对其中的内容自行加以判断，并承担因该内容而引起的所有风险，包括因依赖于内容的正确性、完整性或实用性而产生的风险。广联达无法且不会对因您的行为而导致的任何损失或损害承担责任。</b
                  >
                </p>
                <p>
                  1.4
                  <b>您须对自己的登录信息保密以使其不被其他人获取或使用，并且对您在本网站账户下的所有行为负责。</b
                  >您必须在第一时间将任何有可能触犯法律的、未获得合法授权或怀疑为未获得合法授权的行为，以合理的方式告知广联达，广联达不对您因未能遵守上述要求而造成的损失承担责任。
                </p>
                <p>
                  1.5
                  <b
                  >请勿将您的账号与密码泄露、转让、出售或出借给他人，若您授权他人使用账户，应对被授权人在该账号下发生的所有行为负全部责任。</b
                  >
                </p>
              </li>
              <li>
                <p class="title-1">2．服务内容</p>
                <p>2.1 广联达产品服务的具体内容由广联达根据实际情况提供。</p>
                <p>2.2我们将会根据客户需求随时更新广联达服务，请以页面中的具体服务内容为准。</p>
                <p>
                  2.3
                  为使用广联达服务，您必须自行通过有合法资质的第三方向您提供的互联网接入服务，进入国际互联网，并自行支付相关服务费用。此外，您必须自行配备与国际联网连线所需之一切必要装备，包括计算机、数据机或其它设备，并为之负责。
                </p>
                <p>
                  2.4
                  <b
                  >鉴于网络服务的特殊性，您同意：经事先告知，广联达有权随时变更、中断或终止部分或全部的网络服务（包括收费网络服务）。但是在紧急情况下，广联达将有可能无法进行事先告知。广联达将竭尽全力履行网络运营者的职责，但是广联达不担保网络服务不会中断，也无法对网络服务的及时性、安全性、准确性做出担保。</b
                  >
                </p>
                <p>
                  2.5 广联达需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，<b
                >如因此造成网络服务（包括收费网络服务）在合理范围内的中断，广联达将在合理期限内进行告知，并且无需为此承担任何责任。</b
                >
                </p>
                <p>
                  2.6
                  <b
                  >由于广联达无法控制的原因而造成的商业损失、迟延履行或未履行，以及非因广联达违反义务而造成的任何损害，广联达不承担任何责任。</b
                  >
                </p>
                <p>
                  2.7
                  <b
                  >广联达服务系依“现状”“包含所有缺陷”和“现有”的基础上授予许可。在法律允许的最大范围内，除明确列于本协议之内容外，我们不提供任何其他的条件、声明或做任何保证。</b
                  >
                </p>
              </li>
              <li>
                <p class="title-1">3．第三方产品和服务</p>
                <p>
                  3.1
                  广联达服务可能包含第三方提供的产品或服务。当用户使用第三方提供的产品或服务时，可能会另有相关的协议或隐私政策，您同样应当认真阅读并遵守。
                </p>
                <p>
                  3.2
                  您须理解并同意：为了向您提供更好的用户体验，当您在使用第三方服务时，第三方可能会接触、使用到您的昵称、头像以及在第三方服务中的相关操作信息等信息。
                </p>
                <p>
                  3.3
                  广联达服务或第三方可能会提供互联网上其他网站或资源的链接。鉴于这些网站与资源不在广联达的控制之下，<b
                >您须理解并同意：广联达不对这些网站或资源的有效性负责，亦不会对源于这些网站或资源的任何内容，包括但不限于广告、产品或其他任何材料负责或做出任何保证。广联达将不承担因使用或依赖于从这些网站或资源中获取的或发布于这些网站或资源中的任何内容、广告、产品或服务而造成的任何损失或损害。</b
                >
                </p>
                <p>
                  3.4
                  <b
                  >如您在使用第三方产品或服务时发生任何纠纷，请直接与第三方联系，广联达不承担任何责任，但会根据需要依法提供必要的协助。</b
                  >
                </p>
              </li>
              <li>
                <p class="title-1">4．权利限制</p>
                <p>
                  4.1
                  未经广联达事先书面同意，您不得对广联达服务（包括但不限于内容或产品中的广告或赞助信息）进行任何形式的许可、出售、租赁、转让、发行或做任何其他商业用途；
                </p>
                <p>4.2 您不得以开发相同或竞争性的服务为目的访问或使用广联达服务；</p>
                <p>
                  4.3
                  除非法律明文规定，否则您不得对广联达服务（包括但不限于内容或产品中的广告或赞助信息）的任何部分以任何形式或方法进行复制、发行、再版、下载、显示、张贴、修改、翻译、合并、利用、分解或反向编译等；
                </p>
                <p>
                  4.4
                  您在使用广联达服务时，必须遵守中华人民共和国相关法律法规的规定，您应同意将不会利用广联达服务进行任何违法或不正当的活动，包括但不限于下列行为∶
                </p>
                <div class="padding-left-30">
                  <p>4.4.1 违背《中华人民共和国宪法》所确立的基本原则的；</p>
                  <p>4.4.2 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
                  <p>4.4.3 损害国家荣誉和利益的；</p>
                  <p>4.4.4 煽动民族仇恨、民族歧视、破坏民族团结的；</p>
                  <p>4.4.5 破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
                  <p>4.4.6 散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
                  <p>4.4.7 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
                  <p>4.4.8 侮辱或者诽谤他人，侵害他人合法权利的；</p>
                  <p>
                    4.4.9 含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它有悖于道德的内容；
                  </p>
                  <p>4.4.10 含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；</p>
                </div>

                <p>4.5 您应同意不利用广联达服务从事下列行为：</p>
                <div class="padding-left-30">
                  <p>4.5.1 上传或发布电脑病毒、蠕虫、恶意代码、故意破坏或改变计算机系统或数据的软件；</p>
                  <p>4.5.2 未获授权的情况下，收集其他用户的信息或数据，例如电子邮箱地址等；</p>
                  <p>4.5.3 禁用本网站的网络连接，给本网站造成过度的负担或以其他方式干扰或损害网站服务器和网络链接；</p>
                  <p>4.5.4 在未获授权的情况下，尝试访问本网站、服务器或本网站的网络链接；</p>
                  <p>4.5.5 干扰、破坏其它用户正常使用广联达服务。</p>
                </div>
                <p>
                  4.6
                  <b
                  >您违反本协议或相关的服务条款的规定，并因此导致任何第三方主张的任何索赔、要求或损失（包括合理的律师费），您同意赔偿广联达及其合作公司、关联公司，并使之免受损害。就此，广联达有权视您的行为性质，采取包括但不限于删除用户发布的信息内容、暂停使用许可、终止服务、限制使用、收回广联达账号、追究法律责任等措施。对恶意注册广联达账号或利用广联达账号进行违法活动、干扰、骚扰、欺骗其他用户以及其他违反本协议的行为，广联达有权收回其账号。同时，广联达将根据司法部门的要求，协助调查。</b
                  >
                </p>
                <p>
                  4.7
                  您须对自己在使用广联达服务过程中的行为承担法律责任。您承担法律责任的形式包括但不限于：对受到侵害者进行赔偿，以及在广联达先行承担了因您的行为而导致的行政处罚或侵权损害赔偿责任后，您应给予广联达等额的赔偿。
                </p>
                <p>
                  4.8
                  广联达或广联达委托的第三方有权对您使用广联达服务的行为及信息进行审查、监督及处理，包括但不限于用户个人信息（账号信息等）、发布内容（如位置、文字、图片、音频、视频、商标、专利、出版物等）、用户行为（如构建关系、@信息、评论、私信、参与话题、参与活动、营销信息发布、举报投诉等）等范畴。
                </p>
              </li>
              <li>
                <p class="title-1">5．免责声明与责任限制</p>
                <p>基于互联网的开放性和特殊性，广联达特别提醒您谨慎注意以下风险：</p>
                <p>
                  5.1
                  <b
                  >广联达服务包括但不限于文本、图片、数据、观点、建议、网页与链接。广联达将尽可能为您提供准确的信息，但广联达并不对信息的准确性与完整性承担任何义务或责任。您应当对其他用户使用广联达服务所产生的信息进行独立判断并承担因依赖该内容而引起的所有风险（包括但不限于因对内容的正确性、完整性或实用性的依赖而产生的风险），以及因用户个人信息被其他用户获知而带来的风险。您了解并确认，广联达不对广联达服务用户之间的纠纷承担任何责任。</b
                  >
                </p>
                <p>
                  5.2
                  <b>请您切勿透露任何涉及财产、账号及密码等的重要信息，否则，您应自行承担由此带来的损失。</b>
                </p>
                <p>
                  5.3
                  <b
                  >如果您或其他用户违反本协议而导致被广联达依约处理的，您在广联达服务中的内容、信息等数据将可能会受到影响。</b
                  >
                </p>
                <p>
                  5.4
                  <b
                  >如果我们违反本协议，您同意广联达对您的唯一的补救措施为针对您的直接且已证明的损害向您退款，且退款金额的上限为发生损害或违约的当年度您向广联达支付的该项服务的年度授权服务费（或，当您以免费的方式获得广联达服务时此赔偿将不高于壹元人民币）。您不能要求其他任何损害或损失赔偿，包括但不限于直接损害、后果性损害、特别的损害、利润损失、附带的或惩罚性损害赔偿。在法律允许的最大范围内，这些限制和排除条款适用于与本协议相关的任何事项或任何索赔，即使这一补救措施并未完全补偿您的所有损失，也未能达到其根本目的，或者我们知道或应当知道可能会发生损失。</b
                  >
                </p>
                <p>
                  5.5
                  <b
                  >对于超出广联达合理控制范围的情况（例如，因恶意破坏、意外事故、操作环境不符或其他设备故障）造成广联达无法履行或延迟履行服务义务的，广联达对此不承担任何责任。广联达将尽最大努力降低这些事件的影响，并履行未受影响的义务。</b
                  >
                </p>
              </li>
              <li>
                <p class="title-1">6．保证</p>
                <p>
                  6.1
                  <b
                  >广联达不提供与您使用服务相关的任何明示或默示的保证、保障或条件。您了解您需要自行承担使用服务带来的风险，并了解我们是在“现状”、“包含所有缺陷”和“现有”的基础上提供服务。广联达不保证服务的准确性或时效性。根据您当地的法律，您可能享有某些权利，如果适用，这些条款中的任何规定均无意影响这些权利。您承认，计算机和电信系统可能会出现故障或偶尔会发生停机，我们不保证服务在任何情况下均无中断、及时、安全、无错误、不发生内容丢失，也不对与计算机网络的连接或传输作任何保证。</b
                  >
                </p>
                <p>
                  6.2
                  <b
                  >在您当地法律允许的范围内，我们排除有关适销性、质量满意度、特定用途的适用性、无工艺问题和不侵权的任何默示保证。</b
                  >
                </p>
              </li>
              <li>
                <p class="title-1">7．不可抗力</p>
                <p>
                  7.1
                  您理解并同意，在使用广联达服务的过程中，可能会遇到不可抗力等风险因素，使广联达服务发生中断。不可抗力是指不能预见、不能避免并不能克服的且对一方或双方造成重大影响的客观事件，包括但不限于自然灾害如洪水、地震、瘟疫流行和风暴等，以及社会事件如战争、动乱、政府行为等。出现上述情况时，广联达将在第一时间与相关单位配合，及时进行修复，但是在法律允许的范围内广联达免于承担由此给您造成的损失。
                </p>
                <p>7.2 在法律允许的范围内，因以下情形导致的服务中断或受阻，广联达将不承担责任：</p>
                <div class="padding-left-30">
                  <p>
                    7.2.1 非因广联达违反作为网络运营商的义务而遭受的计算机病毒、木马病毒或其他恶意程序、黑客攻击的破坏；
                  </p>
                  <p>7.2.2 您或广联达的电脑软件、系统、硬件和通信线路出现故障；</p>
                  <p>7.2.3 您错误或不当操作；</p>
                  <p>7.2.4 您通过非广联达授权的方式使用本服务；</p>
                  <p>7.2.5 其他广联达无法控制或合理预见的情形。</p>
                </div>
              </li>
              <li>
                <p class="title-1">8．知识产权</p>
                <p>
                  8.1
                  除另有特别声明外，广联达在服务中提供的内容（包括但不限于网页、文本、图片、音频、视频、图表、软件等）的知识产权归广联达所有，您在使用广联达服务过程中所产生的内容的知识产权归您或相关权利人所有。这些权利不论是否已经登记或注册，亦不论在哪个国家或地区产生，均受到该国家或地区的著作权法、商标法、专利法、反不正当竞争法或相关法律法规的保护。若该国家或地区加入相关国际条约，则这些权利同样受到该等国际条约的保护；若这些权利进行了领土延伸，则受到领土延伸目标国家或地区相关法律法规的保护。
                </p>
                <p>
                  8.2
                  除另有特别声明外，广联达提供本服务时所依托的软件的著作权、专利权及其他知识产权均归广联达所有。未经广联达或相应的权利人事先书面同意，您不得实施、利用、转让或许可任何第三方实施、利用或转让上述知识产权；您不得修改、出租、出借、出售、发行或创建相关衍生作品，或以其他方式使用上述全部或任一部分知识产权。
                </p>
                <p>
                  8.3
                  广联达在本服务中所使用的任何商业标志、标识、商号、企业名称、域名、刊号及其他含有如“广联达”、“GLODON”等的特有标识，其专有权归广联达所有。
                </p>
                <p>
                  8.4 上述及其他任何广联达服务所包含的内容之知识产权均受到法律保护。<b
                >您不得：规避或禁用任何广联达服务所采用的任何内容保护系统或数字版权管理技术；反向编译、逆向工程、反向汇编或以其他方式将任何广联达服务还原为可阅读形式；移除或隐藏标识、版权或其他所有权标识；以违反法规，或未获合法授权，或假借、冒充、搭便车等的方式经营、开展、使用与我们相同或近似的产品、服务、品牌，引人误解或混淆该等产品、服务、品牌等与我们存在特定之联系。</b
                >
                </p>
                <p>
                  8.5如果广联达服务配置为允许使用我们拥有或许可的软件、内容、虚拟物品或其他材料，我们授予您有限的、非专有的、不可转授权的、不可转让的访问或使用这些软件、内容或其他材料的权利，但仅限于您个人使用，您无权在现在已知或将来出现的任何媒体形式或任何渠道中复制、发行、向公众传播、提供或变相提供这些软件、内容或其它材料。
                </p>
                <p>
                  8.6
                  除上述规定以外，如果您认为有人复制或在广联达用户中心上发布您的作品，并已构成对您著作权的侵犯，请及时与我们联系，并向我们发送包含如下信息的书面通知：
                </p>
                <div class="padding-left-30">
                  <p>8.6.1 证明您作为涉嫌侵权作品权利人的权属证明；</p>
                  <p>8.6.2 您准确的身份证明、住址及联系方式；</p>
                  <p>8.6.3 涉嫌侵权的内容在广联达用户中心上的位置；</p>
                  <p>8.6.4 对您认为被侵权作品的描述；</p>
                  <p>8.6.5 您的作品著作权遭受侵犯的相关证据；</p>
                  <p>
                    8.6.6
                    在您同意承担伪证处罚之后果的前提下，您应当提交书面声明，以表明您在上述通知中所述的内容是准确且真实的。
                  </p>
                </div>
              </li>
              <li>
                <p class="title-1">9．服务的变更、中断、终止</p>
                <p>9.1 经事先告知，广联达可以对服务内容进行变更，或者中断、中止或终止广联达服务。</p>
                <p>9.2 如发生下列任何一种情形，广联达有权不经通知而中止或终止向您提供的服务：</p>
                <div class="padding-left-30">
                  <p>
                    9.2.1 根据法律规定您应提交真实信息，而您提供的个人资料不真实、或与注册信息不一致又未能提供合理证明；
                  </p>
                  <p>9.2.2 您违反相关法律法规或本协议的约定；</p>
                  <p>9.2.3 按照法律规定或主管部门的要求；</p>
                  <p>9.2.4 出于安全原因或其他必要的情形；</p>
                  <p>
                    9.2.5
                    <b>若您使用收费项目，而未按时足额付费，广联达有权中断、中止或终止提供服务。</b>
                  </p>
                </div>
                <p>
                  9.3
                  <b
                  >您应自行负责备份存储在广联达服务中的数据。如果您的服务被终止，广联达可以从服务器上永久地删除您的数据,但法律法规另有规定的除外。服务终止后，广联达没有义务向您返还数据。</b
                  >
                </p>
              </li>
              <li>
                <p class="title-1">10．法律适用与管辖</p>
                <p>10.1 本协议签订地为中华人民共和国北京市海淀区。</p>
                <p>
                  10.2
                  <b>本协议的订立、生效、履行、解释与争议解决，均适用中华人民共和国大陆地区法律（不包括冲突法）。</b>
                </p>
                <p>
                  10.3
                  <b
                  >若您和广联达之间发生任何纠纷或争议，双方首先应友好协商解决；协商不成的，您同意将纠纷或争议提交本协议签订地有管辖权的人民法院管辖。</b
                  >
                </p>
                <p>10.4 本协议条款无论因何种原因部分无效或不可执行，其余条款仍然有效，并对双方具有约束力。</p>
                <p>10.5本协议条款终止后，该条款中依其性质应继续有效的规定，将继续有效。</p>
              </li>
              <li>
                <p class="title-1">11．其他</p>
                <p>
                  11.1 请务必仔细阅读
                  <a
                    target="_blank" @click="handleOpenArgument('/platform/privacy')" style="cursor: pointer"
                  >《隐私政策》</a
                  >
                  ，
                  <a
                    target="_blank" @click="handleOpenArgument('/platform/privacy')" style="cursor: pointer"
                  >《隐私政策》</a
                  >
                  为本协议不可分割的一部分，与本协议具有同等法律效力。某些广联达服务可能附有补充约定，例如就特定的平台、事项、软件、应用程序或其他服务的规约。任何补充约定均为本协议的补充，如有冲突，以补充约定为准。
                </p>
                <p>
                  11.2
                  <b
                  >广联达对本协议条款中任何规定的放弃不应被视为对该规定或任何其他规定的进一步或持续的放弃。广联达未依本协议条款主张权利，不应构成对该权利或该条款的放弃。</b
                  >
                </p>
                <p>11.3 如果您对本协议或广联达服务有任何意见或建议，欢迎与广联达用户中心客服联系。</p>
                <p>11.4 <b>联系方式:</b></p>
                <div class="padding-left-30">
                  <p>公司：广联达科技股份有限公司</p>
                  <p>地址：北京市海淀区东北旺西路10号院中关村软件园13号楼广联达信息大厦</p>
                  <p>邮编：100193</p>
                  <p>如有关售后服务问题请直接联系：4000-166-166。</p>
                  <p>电子信箱：<a href="mailto:webmaster@glodon.com">webmaster@glodon.com</a></p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AgreementPage',
  components: {},
  data () {
    return {}
  },

  computed: {},

  mounted () {
  },

  methods: {
    handleOpenArgument (dialogType) {
      let { href } = this.$router.resolve({
        path: dialogType
      })
      window.open(href, '_blank')
    }
  }
}
</script>
<style lang="scss" scoped>
@import './css/agreeFile.scss'; //这个分号一定要写，要不会报错
</style>
